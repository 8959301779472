define("ember-pell/components/pell-editor", ["exports", "ember-pell/pell"], function (_exports, _pell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    pellOptions: null,
    onChange: function onChange() {} /*html*/,
    valueObserver: Ember.observer('value', function () {
      if (this.get('pell')) {
        this._setValue();
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var options = this._options();
      var pellInstance = _pell.default.init(options);
      var contentClass = options.classes && options.classes.content || 'pell-content';
      var contentClassSelector = ".".concat(contentClass.split(' ').join('.'));
      this.set('pell', pellInstance.querySelector(contentClassSelector));
      this._setValue();
    },
    _options: function _options() {
      return Object.assign({}, this.get('pellOptions'), {
        element: this.element,
        onChange: this.onChange
      });
    },
    _setValue: function _setValue() {
      var val = this.get('value');
      if (this.get('pell').innerHTML !== val && typeof val !== 'undefined') {
        this.get('pell').innerHTML = val;
      }
    }
  });
  _exports.default = _default;
});