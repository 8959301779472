define("ember-cookies/services/cookies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0) { ; } } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var keys = Object.keys;
  var assign = Object.assign || Ember.assign || Ember.merge;
  var DEFAULTS = {
    raw: false
  };
  var MAX_COOKIE_BYTE_LENGTH = 4096;
  var _default = Ember.Service.extend({
    _isFastBoot: Ember.computed.reads('_fastBoot.isFastBoot'),
    _fastBoot: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      return owner.lookup('service:fastboot');
    }),
    _document: Ember.computed(function () {
      return document;
    }),
    _getDocumentCookies: function _getDocumentCookies() {
      var all = this.get('_document.cookie').split(';');
      var filtered = this._filterDocumentCookies(Ember.A(all));
      return filtered.reduce(function (acc, cookie) {
        if (!Ember.isEmpty(cookie)) {
          var _cookie = _slicedToArray(cookie, 2),
            key = _cookie[0],
            value = _cookie[1];
          acc[key.trim()] = (value || '').trim();
        }
        return acc;
      }, {});
    },
    _getFastBootCookies: function _getFastBootCookies() {
      var fastBootCookies = this.get('_fastBoot.request.cookies');
      fastBootCookies = Ember.A(keys(fastBootCookies)).reduce(function (acc, name) {
        var value = fastBootCookies[name];
        acc[name] = {
          value: value
        };
        return acc;
      }, {});
      var fastBootCookiesCache = this._fastBootCookiesCache || {};
      fastBootCookies = assign({}, fastBootCookies, fastBootCookiesCache);
      this._fastBootCookiesCache = fastBootCookies;
      return this._filterCachedFastBootCookies(fastBootCookies);
    },
    read: function read(name) {
      var _this = this;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      options = assign({}, DEFAULTS, options || {});
      (false && !(Ember.isEmpty(options.domain) && Ember.isEmpty(options.expires) && Ember.isEmpty(options.maxAge) && Ember.isEmpty(options.path)) && Ember.assert('Domain, Expires, Max-Age, and Path options cannot be set when reading cookies', Ember.isEmpty(options.domain) && Ember.isEmpty(options.expires) && Ember.isEmpty(options.maxAge) && Ember.isEmpty(options.path)));
      var all;
      if (this.get('_isFastBoot')) {
        all = this._getFastBootCookies();
      } else {
        all = this._getDocumentCookies();
      }
      if (name) {
        return this._decodeValue(all[name], options.raw);
      } else {
        Ember.A(keys(all)).forEach(function (name) {
          return all[name] = _this._decodeValue(all[name], options.raw);
        });
        return all;
      }
    },
    write: function write(name, value) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      options = assign({}, DEFAULTS, options || {});
      (false && !(!options.signed) && Ember.assert("Cookies cannot be set as signed as signed cookies would not be modifyable in the browser as it has no knowledge of the express server's signing key!", !options.signed));
      (false && !(Ember.isEmpty(options.expires) || Ember.isEmpty(options.maxAge)) && Ember.assert('Cookies cannot be set with both maxAge and an explicit expiration time!', Ember.isEmpty(options.expires) || Ember.isEmpty(options.maxAge)));
      value = this._encodeValue(value, options.raw);
      (false && !(this._isCookieSizeAcceptable(value)) && Ember.assert("Cookies larger than ".concat(MAX_COOKIE_BYTE_LENGTH, " bytes are not supported by most browsers!"), this._isCookieSizeAcceptable(value)));
      if (this.get('_isFastBoot')) {
        this._writeFastBootCookie(name, value, options);
      } else {
        (false && !(!options.httpOnly) && Ember.assert('Cookies cannot be set to be HTTP-only from a browser!', !options.httpOnly));
        this._writeDocumentCookie(name, value, options);
      }
    },
    clear: function clear(name) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      options = assign({}, options || {});
      (false && !(Ember.isEmpty(options.expires) && Ember.isEmpty(options.maxAge) && Ember.isEmpty(options.raw)) && Ember.assert('Expires, Max-Age, and raw options cannot be set when clearing cookies', Ember.isEmpty(options.expires) && Ember.isEmpty(options.maxAge) && Ember.isEmpty(options.raw)));
      options.expires = new Date('1970-01-01');
      this.write(name, null, options);
    },
    exists: function exists(name) {
      var all;
      if (this.get('_isFastBoot')) {
        all = this._getFastBootCookies();
      } else {
        all = this._getDocumentCookies();
      }
      return all.hasOwnProperty(name);
    },
    _writeDocumentCookie: function _writeDocumentCookie(name, value) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var serializedCookie = this._serializeCookie(name, value, options);
      this.set('_document.cookie', serializedCookie);
    },
    _writeFastBootCookie: function _writeFastBootCookie(name, value) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var responseHeaders = this.get('_fastBoot.response.headers');
      var serializedCookie = this._serializeCookie.apply(this, arguments);
      if (!Ember.isEmpty(options.maxAge)) {
        options.maxAge *= 1000;
      }
      this._cacheFastBootCookie.apply(this, arguments);
      var replaced = false;
      var existing = responseHeaders.getAll('set-cookie');
      for (var i = 0; i < existing.length; i++) {
        if (existing[i].startsWith("".concat(name, "="))) {
          existing[i] = serializedCookie;
          replaced = true;
          break;
        }
      }
      if (!replaced) {
        responseHeaders.append('set-cookie', serializedCookie);
      }
    },
    _cacheFastBootCookie: function _cacheFastBootCookie(name, value) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var fastBootCache = this._fastBootCookiesCache || {};
      var cachedOptions = assign({}, options);
      if (cachedOptions.maxAge) {
        var expires = new Date();
        expires.setSeconds(expires.getSeconds() + options.maxAge);
        cachedOptions.expires = expires;
        delete cachedOptions.maxAge;
      }
      fastBootCache[name] = {
        value: value,
        options: cachedOptions
      };
      this._fastBootCookiesCache = fastBootCache;
    },
    _filterCachedFastBootCookies: function _filterCachedFastBootCookies(fastBootCookies) {
      var _this$get = this.get('_fastBoot.request'),
        requestPath = _this$get.path,
        protocol = _this$get.protocol;

      // cannot use deconstruct here
      var host = this.get('_fastBoot.request.host');
      return Ember.A(keys(fastBootCookies)).reduce(function (acc, name) {
        var _fastBootCookies$name = fastBootCookies[name],
          value = _fastBootCookies$name.value,
          options = _fastBootCookies$name.options;
        options = options || {};
        var _options = options,
          optionsPath = _options.path,
          domain = _options.domain,
          expires = _options.expires,
          secure = _options.secure;
        if (optionsPath && requestPath.indexOf(optionsPath) !== 0) {
          return acc;
        }
        if (domain && host.indexOf(domain) + domain.length !== host.length) {
          return acc;
        }
        if (expires && expires < new Date()) {
          return acc;
        }
        if (secure && !(protocol || '').match(/^https/)) {
          return acc;
        }
        acc[name] = value;
        return acc;
      }, {});
    },
    _encodeValue: function _encodeValue(value, raw) {
      if (Ember.isNone(value)) {
        return '';
      } else if (raw) {
        return value;
      } else {
        return encodeURIComponent(value);
      }
    },
    _decodeValue: function _decodeValue(value, raw) {
      if (Ember.isNone(value) || raw) {
        return value;
      } else {
        return decodeURIComponent(value);
      }
    },
    _filterDocumentCookies: function _filterDocumentCookies(unfilteredCookies) {
      return unfilteredCookies.map(function (c) {
        var separatorIndex = c.indexOf('=');
        return [c.substring(0, separatorIndex), c.substring(separatorIndex + 1)];
      }).filter(function (c) {
        return c.length === 2 && Ember.isPresent(c[0]);
      });
    },
    _serializeCookie: function _serializeCookie(name, value) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var cookie = "".concat(name, "=").concat(value);
      if (!Ember.isEmpty(options.domain)) {
        cookie = "".concat(cookie, "; domain=").concat(options.domain);
      }
      if (Ember.typeOf(options.expires) === 'date') {
        cookie = "".concat(cookie, "; expires=").concat(options.expires.toUTCString());
      }
      if (!Ember.isEmpty(options.maxAge)) {
        cookie = "".concat(cookie, "; max-age=").concat(options.maxAge);
      }
      if (options.secure) {
        cookie = "".concat(cookie, "; secure");
      }
      if (options.httpOnly) {
        cookie = "".concat(cookie, "; httpOnly");
      }
      if (!Ember.isEmpty(options.path)) {
        cookie = "".concat(cookie, "; path=").concat(options.path);
      }
      return cookie;
    },
    _isCookieSizeAcceptable: function _isCookieSizeAcceptable(value) {
      // Counting bytes varies Pre-ES6 and in ES6
      // This snippet counts the bytes in the value
      // about to be stored as the cookie:
      // See https://stackoverflow.com/a/25994411/6657064
      var _byteCount = 0;
      var i = 0;
      var c;
      while (c = value.charCodeAt(i++)) {
        /* eslint-disable no-bitwise */
        _byteCount += c >> 11 ? 3 : c >> 7 ? 2 : 1;
        /* eslint-enable no-bitwise */
      }

      return _byteCount < MAX_COOKIE_BYTE_LENGTH;
    }
  });
  _exports.default = _default;
});